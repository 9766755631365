.login {
  &__form {
    input, button {
      outline: none;
      box-shadow: none;
      border: none;
    }
    button {
      background-color: $highlight;
      color: white;
      &:hover {
        background-color: darken($highlight, 2);
      }
    }
  }
}