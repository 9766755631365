.features-icons {
  padding-top: 3rem;
  padding-bottom: 3rem;
  .features-icons-item {
    max-width: 20rem;
    .features-icons-icon {
      height: 7rem;
      i {
        font-size: 4.5rem;
      }
    }
    &:hover {
      .features-icons-icon {
        i {
          font-size: 5rem;
        }
      }
    }
  }
}
