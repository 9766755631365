@import './../_colors';
$dropdown-position: calc(80% - 150px);
$dropdown-position-mobile: calc(100% - 150px);

.header-container {
	position: fixed;
	width: 100%;
	height: 60px;
	top: 0;
	left: 0;
  background-color: $off-white;
  z-index: 10;
}
.secondary-header-container {
	position: fixed;
	width: 100%;
	height: 40px;
	top: 60px;
	left: 0;
  background-color: white;
  z-index: 9;
  .header-navigation {
    height: 100%;
    max-width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    @media(max-width: 600px) {
      max-width: 95%;
    }
  }
  .header-nav-link {
    cursor: pointer;
    &:hover {
      color: $highlight;
    }
    &-active {
      color: $highlight;
    }
  }
}
.header {
	height: 100%;
	max-width: 80%;
	background: $off-white;
  display: flex;
  margin: 0 auto;
  &__logo {
    width: 60px;
    height: 60px;
    display: flex;
    img {
      width: 40px;
      margin: auto;
    }
  }
  &__logout {
    height: 60px;
    display: flex;
    p {
      margin: auto;
      font-size: 80%;
      color: lighten($text, 2);
      &:hover {
        color: $text;
      }
    }
    position: absolute;
    top: 0;
    left: calc(100% - 220px);
    @media(min-width: 600px) {
      left: calc(80% - 220px);
    }
  }
  &__content {
    height: 60px;
    width: 150px;
    overflow: hidden;
    position: absolute;
    left: $dropdown-position-mobile;
    @media(min-width: 600px) {
      left: $dropdown-position;
    }
    display: flex;
    border-left: 2px solid $off-white;
    border-right: 2px solid $off-white;
    &:hover {
      background-color: lighten(#f7f7f7,1);
    }
    cursor: pointer;
    .current-portfolio {
      .caret-container {
        padding-left: 10px;
      }
      .fas {
        transition: all ease-in-out 0.2s;
      }
      .spin {
        transform: rotate(180deg);
      }
      margin: auto;
    }
  }
  &__dropdown {
    position: absolute;
    background: white;
    left: $dropdown-position-mobile;
    @media(min-width: 600px) {
      left: $dropdown-position;
    }
    width: 150px;
    top: 60px;
    z-index: 100000;
    height: unset;
    transition: all ease-in-out 0.2s;
    transform: scale(1,1);
    transform-origin: top;
    &--collapse {
      transform: scale(1, 0) !important;
      transform-origin: top;
    }
    ul {
      list-style: none;
      width: 100%;
      margin-bottom: 0;
      padding: 0;
      li {
        width: 100%;
        height: 60px;
        display: flex;
        border-left: 2px solid $off-white;
        border-right: 2px solid $off-white;
        border-top: 2px solid #f7f7f7;
        cursor: pointer;
        &:hover {
          background-color: lighten(#f7f7f7,1);
        }
        &:not(:last-child) {
          border-top: 2px solid $off-white;
        }
        p {
          margin: auto;
          text-decoration: none;
          color: $text;
          cursor: pointer;
          &:hover {
            color: $text;
          }
        }
      }
    }
  }
}
