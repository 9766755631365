.navbar {
  .nav-item {
    padding: 1em;
    @media(max-width: 991px) {
      padding: .2em;
      text-align: center;
    }
    .btn-app {
      background: $highlight;
      color: white;
      cursor: pointer;
      &:hover {
        color: white;
        background: darken($highlight, 5);
      }
    }
  }
}

.header-subtitle {
  color: white;
}

.footer {
  a {
    color: white;

  }
}
