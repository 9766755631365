.login {
  &__form {
    input, button {
      outline: none;
      box-shadow: none;
      border: none;
    }
    button {
      background-color: $highlight;
      color: white;
      &:hover {
        background-color: darken($highlight, 2);
      }
    }
  }
}

.horse-in-race {
  padding-right: 2px;
}

.jockey-img-cell {
  position: relative;
  .form {
    position: absolute;
    bottom:0;
    width: 100%;
    margin-left: -50%;
    left:50%;
  }
}

.race-info-container {
  padding-left: .5em;
}

.race-header, .race-details {
  position: relative;
  display: flex;
  padding-bottom: .5em;
  justify-content: flex-start;
  align-items: center;
  & > p {
    margin-right: 2em;
  }
  & > img {
    margin-right: 2em;
  }
  @media(max-width: 720px) {
    font-size: 70%;
    & > p {
      margin-right: 1em;
    }
    & > img {
      margin-right: 1em;
    }
  }
}

.img-thumb {
  @media(max-width: 600px) {
    width: 10px;
  }
  width:20px;
}
.thoroughbred-icon {
  width: 35px;
}

.box-img {
  width: 35px;
  height: 35px;
  margin: auto;
  display: flex;
  border: 2px solid $text;
  border-radius: 3px;
  .box-number {
    margin: auto;
    font-size: 1.5em;
  }
}
