

@import './colors';
@import 'components/login';
@import '/components/header';
@import '/components/race';
@import '/components/meeting';
@import '/landing/landing-page';
@import '/components/landing';

* {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
}
body,html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: $off-white;
  color: $text;
  width: 100%;
}

.bg-text {
  background: $text;
  color: white;
}
a:active, a:focus {
  text-decoration: none !important;
}
.w-50-auto {
  width: 100px;
  margin: auto;
}
input, button {
  box-shadow: none !important;
  outline: none !important;
  -webkit-appearance: none !important;
  border: none;
  background: white;
  height: 40px;
  margin-right: 10px;
  padding: 10px;
  min-width: 50px;
  font-size: 95%;
}
button {
  background: $highlight;
  color: #ffffff;
}

.bg-success {
  background: #28a745 !important;
}

.rounded {
  border-radius: 2px;
}

.App {
  margin-top: 140px;
  width: 100%;
}
.link {
  cursor: pointer;
  &:hover {
    color: darken($text, 1) !important;
  }
}
.btn {
  background-color: $highlight;
  color: white;
  border-radius: 2px;
  &:hover {
    background-color: darken($highlight,5);
    color: white;
  }
  &-delete {
    background-color: $delete;
    &:hover {
      background-color: darken($delete,5);
    }
  }
  &-alert {
    background-color: $alert;
    &:hover {
      background-color: darken($alert,5);
    }
  }
}

.fa-highlighted {
  color: $highlight !important;
}
.horse-container {
  -webkit-box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.05);
  background-color: white;
}
.horse-table {
  background-color: white;
  border: 1px solid $off-white;
  td,tr,th {
    border: 1px solid $off-white;
    font-size: 85%;
    @media(max-width: 460px) {
      font-size: 70%;
    }
    text-align: center;
    height: 100%;
    vertical-align: middle;
  }
}

.horse-search {
  border: 0;
  background: white;
  margin: 25px 0;
}

.portfolio-row {
  display: flex;
  background: white;
  & > * {
    flex: 1;
    margin: auto;
  }
}

.sexy-form {
  width: 100%;
}

.sexy-button {
  box-shadow: none !important;
  outline: none !important;
  -webkit-appearance: none !important;
  border: none;
  background: $highlight;
  height: 40px;
  color: white;
  margin-right: 10px;
  padding: 10px;
  min-width: 50px;
  font-size: 95%;
}

.clickable {
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

.pointer {
  cursor: pointer;
}

td.selected {
  background-color: $highlight;
  color: white;
}

p.no-margin {
  margin: 0;
}

.margin-auto {
  margin: auto;
}

.runner-result {
  position: absolute;
}

.text-highlight {
  color: $highlight;
}
