.meetings-container {
  max-width: 100%;
  overflow: auto;
}
.meetings-table {
  .meetings-header {
    background: $highlight;
    color: white;
    > th {
      padding: 1em;
    }
  }
  .race-cell {
    cursor: pointer;
    padding: 1em;
  }
  > td {
    overflow: auto;
  }
}
